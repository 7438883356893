import React from 'react';
import { Nav } from 'react-bootstrap';

/* 
import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  OverlayTrigger,
  Tooltip,
  Dropdown
} from 'react-bootstrap';
import Login from 'components/authentication/simple/Login';
import Registration from 'components/authentication/simple/Registration';
import AppContext from 'context/Context'; */

const LandingRightSideNavItem = () => {
  /*   const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
 */
  return (
    <Nav navbar className="ms-auto">
      {/*       <Nav.Item>
        <Nav.Link
          as={Link}
          to="#!"
          onClick={() => setShowRegistrationModal(!showRegistrationModal)}
        >
          Register
        </Nav.Link>
        <Modal
          show={showRegistrationModal}
          centered
          onHide={() => setShowRegistrationModal(false)}
        >
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <Registration />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </Nav.Item> */}
      <Nav.Item>
        <a className="navItemMenuLanding" href="#features">
          Features
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="#get_started">
          Get Started
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="#pricing">
          Pricing
        </a>
      </Nav.Item>
      <Nav.Item>
        <a className="navItemMenuLanding" href="/dashboard/main">
          Login
        </a>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
