import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvancedCountUp from 'components/common/custom-charts/AdvancedCountUp';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const LabeledCountUp = ({ dataset, icon }) => {
  return (
    <Card className="h-100 commit-add-del-card">
      <Card.Body>
        <Row className="flex-between-center">
          <Col className="d-md-flex d-lg-block flex-between-center">
            <FontAwesomeIcon className="commit-add-del-card-icon" icon={icon} />
            <h5 className="mb-md-0 mb-lg-2 advanced-count-up-label">
              {dataset.title}
            </h5>
          </Col>
          <Col xs="auto">
            <h4 className="fs-3 fw-normal text-700">
              <AdvancedCountUp dataset={dataset.ammount} />
            </h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

LabeledCountUp.propTypes = {
  dataset: PropTypes.shape({
    title: PropTypes.string.isRequired,
    ammount: PropTypes.number.isRequired
  }).isRequired,
  icon: PropTypes.string.isRequired
};

export default LabeledCountUp;
