import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Features = () => (
  <Section>
    <SectionHeader
      title="Unlock the Future of Analytics about Software Development"
      subtitle="Gitlights, built on cutting-edge technology, empowers your team with advanced analytics and insights, revolutionizing your development process."
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Features;
