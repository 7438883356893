import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ToolboxComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import { default as React, useRef } from 'react';

echarts.use([ToolboxComponent]);
const getColorForSeries = seriesIndex => {
  const colorNames = [
    'purple',
    'success',
    'amber',
    'pink',
    'orange',
    'info',
    'lime',
    'cyan',
    'amber',
    'pink',
    'warning',
    'danger',
    'light',
    'dark',
    'gray600',
    'green',
    'teal',
    'yellow',
    'deepOrange',
    'brown',
    'blueGray',
    'indigo',
    'deepPurple',
    'deepYellow',
    'lightGreen',
    'deepGreen',
    'lightBlue',
    'cyanBlue',
    'secondary'
  ];
  if (seriesIndex < colorNames.length) {
    return getColor(colorNames[seriesIndex]);
  } else {
    // If more colors are needed, select a primary color
    return getColor('primary');
  }
};

const ComboPieMultiLine = ({ dataset }) => {
  const getOption = () => {
    console.log('DATASET');
    console.log(dataset);
    const seriesData = dataset.slice(1);
    const header1 = dataset[0][0];
    const header2 = dataset[0][1];
    const investmentTypes = seriesData.map(series => series[0]);

    const series = investmentTypes.map((type, index) => {
      const color = getColorForSeries(index);
      return {
        type: 'bar',
        stack: 'one',
        smooth: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' },
        symbolSize: 10,
        lineStyle: { color },
        itemStyle: {
          color,
          borderRadius: [3, 3, 0, 0]
        },
        symbol: 'circle'
      };
    });

    series.push({
      type: 'pie',
      id: 'pie',
      radius: '30%',
      center: ['50%', '28%'],
      emphasis: { focus: 'data' },
      label: {
        formatter: function (value) {
          return value.name + ': ' + value.percent + '%';
        },
        color: getColor('gray-600')
      },
      encode: {
        itemName: header1,
        value: header2,
        tooltip: header2
      }
    });

    const colorArray = investmentTypes.map((type, index) =>
      getColorForSeries(index)
    );

    return {
      color: colorArray,

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: getColor('gray-500')
          },
          label: {
            show: true,
            backgroundColor: getColor('gray-600'),
            color: getColor('gray-100')
          }
        },
        padding: [7, 10],
        backgroundColor: getColor('gray-100'),
        borderColor: getColor('gray-300'),
        textStyle: { color: getColor('dark') },
        borderWidth: 1,
        transitionDuration: 0
      },
      toolbox: {
        top: 0,
        feature: {
          dataView: { show: false },
          magicType: {
            show: true,
            type: ['line', 'bar', 'stack', 'tiled']
          },
          restore: { show: true },
          saveAsImage: { show: true }
        },
        iconStyle: {
          borderColor: getColor('gray-700'),
          borderWidth: 1
        },

        emphasis: {
          iconStyle: {
            textFill: getColor('gray-600')
          }
        }
      },
      dataset: {
        source: dataset
      },
      xAxis: {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: getColor('gray-300')
          }
        },
        axisLabel: {
          color: getColor('gray-600')
        },
        axisPointer: {
          lineStyle: {
            color: getColor('gray-300')
          }
        }
      },
      yAxis: {
        gridIndex: 0,
        axisLabel: {
          color: getColor('gray-600')
        },
        splitLine: {
          lineStyle: {
            color: getColor('gray-200')
          }
        }
      },
      series,
      grid: {
        right: 10,
        left: 5,
        bottom: 5,
        top: '55%',
        containLabel: true
      }
    };
  };

  const chartRef = useRef(null);

  const updateAxisPointer = event => {
    var xAxisInfo = event.axesInfo[0];
    if (xAxisInfo) {
      var dimension = xAxisInfo.value + 1;

      chartRef.current.getEchartsInstance().setOption({
        series: {
          id: 'pie',
          label: {
            formatter: '{b}: {@[' + dimension + ']} ({d}%)'
          },
          encode: {
            value: dimension,
            tooltip: dimension
          }
        }
      });
    }
  };
  const onEvents = {
    updateAxisPointer: updateAxisPointer
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={chartRef}
      option={getOption()}
      style={{ height: '31.25rem' }}
      onEvents={onEvents}
    />
  );
};

ComboPieMultiLine.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.array)
};

export default ComboPieMultiLine;
