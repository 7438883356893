export default [
  {
    media: { icon: 'flag', color: 'info', className: 'fs-4' },
    title: 'Install Gitlights',
    description:
      'Head to the <a href="https://github.com/marketplace/gitlights-analytics">GitHub Marketplace</a> and install the Gitlights application, granting permissions to the repositories you want to analyze and keep track of.'
  },
  {
    media: { icon: 'clock', color: 'success', className: 'fs-5' },
    title: 'Data will be loaded in minutes',
    description:
      'Within minutes, a wealth of graphs and tables with your data will be available on the platform.'
  },
  {
    media: { icon: 'chart-bar', color: 'danger', className: 'fs-6' },
    title: 'Analyze the results',
    description:
      'Review the results; Gitlights will assist you in interpreting them and drawing meaningful conclusions.'
  }
];
