import AdvanceSearchTable from 'components/common/custom-charts/AdvanceSearchTable';
import LabeledCountUp from 'components/common/custom-charts/LabeledCountUp';
import MultiLineStackHistogramV2 from 'components/common/custom-charts/MultiLineStackHistogram';
import SixPackKPIs from 'components/common/custom-charts/SixPackKPIs';
import SmallPieChart from 'components/common/custom-charts/SmallPieChart';
import InfoModal from 'components/common/custom-components/InfoModal';
import {
  DashboardFilterDevelopersContext,
  DashboardFilterRepositoriesContext,
  DashboardFiltersContext
} from 'context/Context';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

const PullRequestsView = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());
  console.log('PullRequestsView rendered');
  const [prsByRepo, setPrsByRepo] = useState([]);
  const [prsSixPackKPIs, setPrsSixPackKPIs] = useState([]);
  const [prsInfoByDevTable, setPrsInfoByDevTable] = useState([]);
  const [prsInfoByRepoTable, setPrsInfoByRepoTable] = useState([]);
  const [multiStackHistogram, setMultiStackHistogram] = useState([]);
  const [multiLineStackHistogram, setMultiLineStackHistogram] = useState([]);
  const [totalReviews, setTotalReviews] = useState([]);
  const [totalConversations, setTotalConversations] = useState([]);
  const [totalComments, setTotalComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const filtersContext = useContext(DashboardFiltersContext);
  const developersFiltersContext = useContext(DashboardFilterDevelopersContext);
  const repositoriesFiltersContext = useContext(
    DashboardFilterRepositoriesContext
  );

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem('waitingPRsResponse') === null) {
      localStorage.setItem('waitingPRsResponse', true);
      axiosInstance
        .get('/get-prs-dashboard/', {
          params: {
            startDate: filtersContext.filters.startDate,
            endDate: filtersContext.filters.endDate,
            developers:
              developersFiltersContext.developersFilter.developers.toString(),
            repositories:
              repositoriesFiltersContext.repositoriesFilter.repositories.toString()
          }
        })
        .then(response => {
          const data = response.data.dashboard_data;

          setTotalReviews(data.total_reviews);
          setTotalConversations(data.total_conversations);
          setTotalComments(data.total_comments);
          setPrsByRepo(data.prs_by_repo);
          setPrsSixPackKPIs(data.prs_six_pack_kpis);
          setPrsInfoByDevTable(data.prs_info_by_dev_table);
          setPrsInfoByRepoTable(data.prs_info_by_repo_table);
          setMultiStackHistogram(data.reviews_conversations_comments_histogram);
          setMultiLineStackHistogram(data.prs_evolution_RSI_EMA);

          setIsLoading(false);
          localStorage.removeItem('waitingPRsResponse');
        })
        .catch(error => {
          localStorage.removeItem('waitingPRsResponse');
          console.error(error);
        });
    } else {
      localStorage.removeItem('waitingPRsResponse');
    }
  }, [filtersContext, developersFiltersContext, repositoriesFiltersContext]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Evolution of Pull Requests with EMA and RSI',
                      description: `
                    <p>This widget offers a detailed visual representation of the historical evolution of pull requests. The purple bars reflect the absolute values of pull requests made in specific periods, providing a clear view of the activity. For a smoother interpretation, the blue bars reveal the Exponential Moving Average (EMA) of pull requests, emphasizing trends over time.</p>

                    <p>Additionally, a green line is incorporated to represent the Relative Strength Index (RSI), a key indicator used in various sectors. In the context of Git development, the RSI offers insight into the team's fitness in terms of the frequency of pull requests. Both EMA and RSI act on the last 4 samples, providing a more accurate view of recent trends.</p>

                    <h5>Benefits and Possible Interpretations:</h5>
                    <ul>
                      <li><strong>Trend Identification:</strong> The EMA provides a smoothed representation of pull request trends over time, making it easier to identify and understand the overarching patterns in the team's contribution. Sudden spikes or declines in EMA may indicate shifts in development momentum.</li>
                      <li><strong>Strength of Development Momentum:</strong> The RSI serves as an indicator of the team's development momentum. A consistently high RSI may suggest a sustained high level of pull request activity, while a declining RSI could signify a potential slowdown.</li>
                      <li><strong>Decision Support for Planning:</strong> The combination of EMA and RSI aids in decision-making for future development planning. Teams can use this information to anticipate periods of high or low activity, allowing for more effective resource allocation and project planning.</li>
                    </ul>

                    <p>Overall, this type of chart serves as a powerful tool for project managers, team leads, and developers alike, offering insights that contribute to informed decision-making, improved collaboration, and efficient resource utilization in the Git development environment.</p>
                  `
                    }}
                  />
                  <MultiLineStackHistogramV2
                    dataset={multiLineStackHistogram}
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
              </Row>

              <Row className="g-5 mb-6">
                <Col
                  className="multi-stack-histogram-lines-of-code"
                  md={9}
                  xxl={9}
                >
                  <InfoModal
                    dataset={{
                      title:
                        'Comments, Reviews, and Conversations in Pull Requests',
                      description: `
                        This stacked bar chart provides a visual representation of the temporal evolution of comments, reviews, and conversations in pull requests. Each segment of the bar represents the contribution of each element, allowing an understanding of how the development team's collaboration has evolved over time. It's important to note that a conversation can contain one or more comments, and these elements can exist outside of a conversation, such as at the root of a pull request. This chart provides a comprehensive view of collaboration dynamics within the team. The presented data is part of the subset of filters applied in the header, facilitating precise customization according to analysis criteria.
                      `
                    }}
                  />

                  <MultiLineStackHistogramV2
                    dataset={multiStackHistogram}
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
                <Col md={3} xxl={3}>
                  <Row className="g-3 mb-3">
                    <InfoModal
                      dataset={{
                        title: 'Pull Requests by Repository',
                        description: `
                          This simple pie chart offers a clear visual representation of the number of pull requests per repository. Each pie segment represents the proportion of pull requests for a specific repository, providing a quick view of the distribution of development activity among different repositories. This data belongs to the subset of filters applied in the header, allowing effective adaptation of the visualization according to specific analysis criteria.
                        `
                      }}
                    />
                    <SmallPieChart
                      dataset={prsByRepo}
                      radius={['100%', '87%']}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <InfoModal
                      dataset={{
                        title: "Conversations, Comments, and Reviews in PR's",
                        description: `
                          These indicators present the absolute value of conversations, comments, and reviews in pull requests. They offer a detailed view of the interaction and review associated with pull requests. It's important to highlight that conversations can contain one or more comments, and these elements can exist outside of a conversation, at the root of a pull request, for example. The included data belongs to the subset of filters applied in the header, providing a specific and relevant representation for the ongoing analysis.
                        `
                      }}
                    />

                    <LabeledCountUp dataset={totalReviews} icon="plus" />
                    <LabeledCountUp dataset={totalConversations} icon="minus" />
                    <LabeledCountUp dataset={totalComments} icon="equals" />
                  </Row>
                </Col>
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: "Developers' Indicators Table in Pull Requests",
                    description: `
                      This table presents the key indicators related to pull requests grouped by developer. You can use the search function or pagination to find specific information about a developer. Clicking on any of the indicators redirects to the developer's detailed page. The included data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Total PRs:</strong> The total number of pull requests created by each developer.</li>
                        <li><strong>Total Reviews:</strong> The total number of reviews conducted by each developer.</li>
                        <li><strong>Total Conversations:</strong> The total number of conversations associated with pull requests for each developer.</li>
                        <li><strong>Reviews per PR:</strong> The average number of reviews per pull request for each developer.</li>
                        <li><strong>Conversations per PR:</strong> The average number of conversations per pull request for each developer.</li>
                        <li><strong>Comments per Conversations:</strong> The average number of comments per conversation for each developer.</li>
                        <li><strong>Time to Merge:</strong> The average time it takes to merge a pull request for each developer, expressed in hours.</li>
                      </ul>
                    `
                  }}
                />
                <AdvanceSearchTable dataset={prsInfoByDevTable} />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: "Repositories' Indicators Table in Pull Requests",
                    description: `
                      This table displays the key indicators related to pull requests and is grouped by repository. You can use the search function or pagination to find information about a specific repository. The presented data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Total PRs:</strong> The total number of pull requests created in each repository.</li>
                        <li><strong>Total Reviews:</strong> The total number of reviews conducted in each repository.</li>
                        <li><strong>Total Conversations:</strong> The total number of conversations associated with pull requests in each repository.</li>
                        <li><strong>Reviews per PR:</strong> The average number of reviews per pull request in each repository.</li>
                        <li><strong>Conversations per PR:</strong> The average number of conversations per pull request in each repository.</li>
                        <li><strong>Comments per Conversations:</strong> The average number of comments per conversation in each repository.</li>
                        <li><strong>Time to Merge:</strong> The average time it takes to merge a pull request in each repository, expressed in hours.</li>
                      </ul>
                    `
                  }}
                />
                <AdvanceSearchTable dataset={prsInfoByRepoTable} />
              </Row>

              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title:
                      'Comparison of Indicators with Average of Other Organizations in Pull Requests',
                    description: `
                      This component compares the key indicators related to pull requests with the average of other organizations. It also shows the upward or downward trend and the percentage of variation. This component is useful for obtaining a reference for each metric outside your organization. The indicators are agnostic to both the number of developers and the size over time of the analyzed sample. The presented data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Average PRs per Developer per Day:</strong> The average number of pull requests created per developer per day compared to the average of other organizations.</li>
                        <li><strong>Average Reviews per Developer per Day:</strong> The average number of reviews conducted per developer per day compared to the average of other organizations.</li>
                        <li><strong>Average Comments per Developer per Day:</strong> The average number of comments made per developer per day compared to the average of other organizations.</li>
                        <li><strong>Average Time to Merge PR (Hours):</strong> The average time it takes to merge a pull request compared to the average of other organizations, expressed in hours.</li>
                        <li><strong>Lines of Code Balance per PR:</strong> The net balance of lines of code per pull request compared to the average of other organizations.</li>
                        <li><strong>Files Changed per PR:</strong> The average number of files modified per pull request compared to the average of other organizations.</li>
                      </ul>
                    `
                  }}
                />
                <SixPackKPIs dataset={prsSixPackKPIs} />
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default PullRequestsView;
