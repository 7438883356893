import useFakeFetch from 'hooks/useFakeFetch';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import PricingDefaultCard from './PricingDefaultCard';
export const pricingData = [
  {
    id: 0,
    name: 'lite_pack',
    title: 'Lite Pack',
    subTitle: 'Up to 10 contributors',
    price: 9.9,
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Commits and Pull request history' },
      { id: 2, title: 'Investment balance' },
      { id: 3, title: 'Developers radar' },
      { id: 4, title: 'Developers map' },
      {
        id: 5,
        title: 'Smart notifications',
        tag: { label: 'New', type: 'success' }
      },
      { id: 6, title: '1 year data retention' }
    ]
  },
  {
    id: 1,
    name: 'starter_pack',
    title: 'Starter Pack',
    subTitle: 'Up to 20 contributors',
    price: 24.9,
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Commits and Pull request history' },
      { id: 2, title: 'Investment balance' },
      { id: 3, title: 'Developers radar' },
      { id: 4, title: 'Developers map' },
      {
        id: 5,
        title: 'Smart notifications',
        tag: { label: 'New', type: 'success' }
      },
      { id: 6, title: '1 year data retention' }
    ]
  },
  {
    id: 2,
    name: 'growth_pack',
    title: 'Growth Pack',
    subTitle: 'Up to 50 contributors',
    price: 49.9,
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Commits and Pull request history' },
      { id: 2, title: 'Investment balance' },
      { id: 3, title: 'Developers radar' },
      { id: 4, title: 'Developers map' },
      {
        id: 5,
        title: 'Smart notifications',
        tag: { label: 'New', type: 'success' }
      },
      { id: 6, title: '1 year data retention' }
    ]
  },
  {
    id: 3,
    name: 'business_pack',
    title: 'Business Pack',
    subTitle: 'Up to 100 contributors',
    price: 74.9,
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Commits and Pull request history' },
      { id: 2, title: 'Investment balance' },
      { id: 3, title: 'Developers radar' },
      { id: 4, title: 'Developers map' },
      {
        id: 5,
        title: 'Smart notifications',
        tag: { label: 'New', type: 'success' }
      },
      { id: 6, title: '1 year data retention' }
    ]
  },
  {
    id: 4,
    name: 'enterprise_pack',
    title: 'Enterprise Pack',
    subTitle: 'More than 100 contributors',
    price: 89.9,
    buttonText: 'Purchase',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Commits and Pull request history' },
      { id: 2, title: 'Investment balance' },
      { id: 3, title: 'Developers radar' },
      { id: 4, title: 'Developers map' },
      {
        id: 5,
        title: 'Smart notifications',
        tag: { label: 'New', type: 'success' }
      },
      { id: 6, title: '1 year data retention' }
    ]
  }
];

const searchParams = new URLSearchParams(window.location.search);
const plan = searchParams.get('plan');
if (plan === 'out-of-free-trial') {
  toast.warning(
    'Your free trial has ended! Upgrade to a plan now to continue enjoying our services! 😊',
    { position: 'top-right', autoClose: false }
  );
}

const PricingDefault = ({ isPurchasable }) => {
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    pricingData,
    500
  );
  return (
    <>
      {isPurchasable && (
        <WidgetSectionTitle
          icon="exclamation-circle"
          title="Select your plan"
          subtitle="Choose a plan according to the number of contributors in your organization. If you select a plan with fewer contributors than you have, there's no problem. However, keep in mind that in that case, only the N contributors with the most recent commit activity will be shown daily (where N is the number of contributors in your plan). For example, if you have 100 developers making commits in your organization but choose the Lite plan for 10 contributors, only the 10 developers with the most recent activity will appear in the metrics each day, losing visibility of the rest. All plans have the same features."
          transform="shrink-4"
          className="mb-4 mt-2"
        />
      )}
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            {priceLoading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              pricing.map(pricing => (
                <PricingDefaultCard
                  key={pricing.id}
                  pricing={pricing}
                  isPurchasable={isPurchasable}
                />
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

PricingDefault.propTypes = {
  isPurchasable: PropTypes.bool
};
PricingDefault.defaultProps = {
  isPurchasable: true
};
export default PricingDefault;
