import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bg1 from 'assets/img/generic/bg-3.jpg';
import videoGitlights from 'assets/img/generic/Gitlights-AD-v3.mp4';

import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';

const Hero = () => {
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg1}
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={11}
          lg={8}
          xl={4}
          className="pb-7 pb-xl-11 text-center text-xl-start hero-text"
        >
          <h1 className="text-white fw-light">
            Bring{' '}
            <span className="fw-bold">
              <Typewriter
                words={[
                  'efficiency',
                  'innovation',
                  'collaboration',
                  'productivity',
                  'insight',
                  'agility'
                ]}
                loop={false}
                cursor={!isRTL ? true : false}
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            to your organization
          </h1>
          <p className="lead text-white opacity-75">
            Gitlights, a powerful Git analytics tool, leverages AI and NLP
            algorithms to provide enriched insights on commits, pull requests,
            and developer skills. Unlock your team's full potential with
            Gitlights.
          </p>
          <a href="/dashboard/main">
            <Button
              variant="outline-light"
              size="lg"
              className="border-2 rounded-pill mt-4 fs-0 py-2"
            >
              Get Started with Gitlights Now!
              <FontAwesomeIcon
                icon="play"
                transform="shrink-6 down-1 right-5"
              />
            </Button>
          </a>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="pb-7 pb-xl-11 text-center text-xl-start hero-text"
        >
          <video width="100%" autoPlay controls>
            <source src={videoGitlights} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
