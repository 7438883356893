import { DashboardFilterRepositoriesContext } from 'context/Context';
import axiosInstance from 'http/axiosConfig';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';

var repositoriesList;
const filterRepositories = inputValue => {
  console.log(repositoriesList);
  return repositoriesList.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadOptions = (inputValue, callback) => {
  axiosInstance
    .get('/get-repositories-list/')
    .then(response => {
      console.log(response.data);
      repositoriesList = response.data.repositories_list;
      callback(filterRepositories(inputValue));
    })
    .catch(error => {
      console.error(error);
    });
};

const FilterRepositoriesInput = () => {
  const { setRepositoriesFilter } = useContext(
    DashboardFilterRepositoriesContext
  );

  const onChange = repositories => {
    console.log('REPOS');
    console.log(repositories);
    const repos = [];
    repositories.forEach(repository => {
      repos.push(repository.value);
    });
    setRepositoriesFilter({ repositories: repos });
  };

  return (
    <AsyncSelect
      isMulti
      onChange={onChange}
      defaultOptions
      cacheOptions
      isSearchable
      classNamePrefix="react-select"
      placeholder="Type here"
      loadOptions={loadOptions}
    />
  );
};

FilterRepositoriesInput.propTypes = {
  dataset: PropTypes.any, // You can update this to the appropriate PropTypes
  icon: PropTypes.string // If 'icon' is expected to be a string
};

export default FilterRepositoriesInput;
