import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

const Cta = () => (
  <Section overlay image={bg2} position="center top" className="light bg-dark">
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <p className="fs-3 fs-sm-4 text-white">
          Elevate your GitHub experience with Gitlights – a powerful web app
          leveraging AI and NLP to analyze commits, pull requests, and developer
          performance. Install now for insightful dashboards and analytics!
        </p>
        <a href="/dashboard/main">
          <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            to="/dashboard/main"
          >
            Get Started with your 15-day free trial Now!
          </Button>
        </a>
      </Col>
    </Row>
  </Section>
);

export default Cta;
