export const createRoundedImage = (imageUrl, size, radius) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Enable CORS if needed
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');

      // Draw the image on the canvas with rounded corners
      ctx.beginPath();
      ctx.arc(radius, radius, radius, Math.PI, 1.5 * Math.PI);
      ctx.lineTo(size - radius, 0);
      ctx.arc(size - radius, radius, radius, 1.5 * Math.PI, 2 * Math.PI);
      ctx.lineTo(size, size - radius);
      ctx.arc(size - radius, size - radius, radius, 0, 0.5 * Math.PI);
      ctx.lineTo(radius, size);
      ctx.arc(radius, size - radius, radius, 0.5 * Math.PI, Math.PI);
      ctx.closePath();
      ctx.clip();

      ctx.drawImage(img, 0, 0, size, size);

      // Convert the canvas to a data URL
      const roundedImageUrl = canvas.toDataURL('image/png');
      resolve(roundedImageUrl);
    };

    img.onerror = function (error) {
      reject(error);
    };

    img.src = imageUrl;
  });
};
