import { DashboardFilterDevelopersContext } from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';
import axiosInstance from 'http/axiosConfig';

var developersList;
const filterDevelopers = inputValue => {
  console.log(developersList);
  return developersList.filter(i =>
    i.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};

const loadOptions = (inputValue, callback) => {
  axiosInstance
    .get('/get-developers-list/')
    .then(response => {
      console.log(response.data);
      developersList = response.data.developers_list;
      callback(filterDevelopers(inputValue));
    })
    .catch(error => {
      console.error(error);
    });
};
const FilterDevelopersInput = () => {
  const { setDevelopersFilter } = useContext(DashboardFilterDevelopersContext);

  const onChange = developers => {
    const devs = [];
    developers.forEach(developer => {
      devs.push(developer.value);
    });
    setDevelopersFilter({ developers: devs });
  };
  return (
    <AsyncSelect
      isMulti
      onChange={onChange}
      defaultOptions
      cacheOptions
      isSearchable
      classNamePrefix="react-select"
      placeholder="Type here"
      loadOptions={loadOptions}
    />
  );
};

FilterDevelopersInput.propTypes = {
  dataset: PropTypes.any, // You can update this to the appropriate PropTypes
  icon: PropTypes.string // If 'icon' is expected to be a string
};

export default FilterDevelopersInput;
