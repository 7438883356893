import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import Modal from 'react-bootstrap/Modal';

const InfoModal = ({ dataset }) => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="widget-item-title-container">
        <h5 className="chart-title">{dataset.title}</h5>
        <div className="chart-info-icon" onClick={() => setModalShow(true)}>
          <FontAwesomeIcon icon="info-circle" />
        </div>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {dataset.title}
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          />
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: dataset.description }} />
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

InfoModal.propTypes = {
  dataset: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired
};

export default InfoModal;
