import illustration1 from 'assets/img/generic/pull_requests.gif';
import illustration2 from 'assets/img/generic/developers_map.gif';
import illustration3 from 'assets/img/generic/investment_balance.gif';
import illustration4 from 'assets/img/generic/developer_detail.gif';

export default [
  {
    icon: 'code',
    iconText: 'Insightful',
    color: 'danger',
    title: 'Commits and Pull Requests Dashboard',
    description:
      "Discover the fascinating world of your code's evolution with our Commits and Pull Requests Dashboard. Visualize historical graphs that unravel added and deleted lines, as well as the overall balance. Use tools like RSI and EMA to assess your development team's performance. Make informed decisions based on developer comments, reviews, and conversations in pull requests. Optimize your analysis by filtering dates, repository, or developer to obtain a comprehensive and detailed view!",
    image: illustration1
  },
  {
    icon: 'users',
    iconText: 'Proficiency',
    color: 'info',
    title: 'Advanced Developer Skills Analysis',
    description:
      "Explore the true potential of your development team with our Advanced Skills Analysis. Understand each skill of your developers thoroughly, identifying strengths and areas for improvement. Discover who excels in problem-solving, who dedicates more time to fixes or refactoring, and who leads in pull request review and commentary. With this tool, maximize your team's performance by knowing their skills perfectly.",
    image: illustration4,
    inverse: true
  },
  {
    icon: 'balance-scale-left',
    iconText: 'Strategic',
    color: 'success',
    title: 'Investment Balance Monitoring',
    description:
      "Gitlights not only tracks your commits but categorizes them with our advanced AI system! Analyze the investment balance of the development team, categorizing commits according to their types: Fixes, Refactoring, New, Development, Security, Documentation, and more! Investigate how this investment aligns with your company's goals and make strategic decisions based on precise and detailed data.",
    image: illustration3
  },
  {
    icon: 'map',
    iconText: 'Collaborative',
    color: 'warning',
    title: 'Developers Map',
    description:
      "Discover the strength of your development team with our exclusive Developers Map. Distinguish between individual contributors and collective contributors to understand your team's unique dynamics. Optimize collaboration and enhance efficiency by clearly identifying the roles and responsibilities of each member.",
    image: illustration2,
    inverse: true
  },
  {
    icon2: 'chart-bar',
    iconText2: 'Benchmarking',
    color2: 'info',
    title2: 'Comparison with Other Teams',
    description2:
      'Gitlights goes beyond by providing benchmark metrics from your industry or companies of your size. Visualize easily understandable comparisons, aligning your performance with that of the competition. Our visual graphs and essential statistics ensure that your company not only keeps up but sets the pace! Stay ahead with comparative data that drives the success of your development team.',
    icon: 'envelope',
    iconText: 'Benchmarking',
    color: 'danger',
    title: 'Smart notificaions',
    description:
      "Stay updated on the development team's progress with our weekly and monthly reports! Our advanced artificial intelligence analyzes data to extract the best insights for you. Don't miss a single detail: receive our smart notifications directly to your email or preferred Slack channel. Don't miss anything about the latest updates on the development team!!"
  }
];
