// AccountView.js
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import axiosInstance from 'http/axiosConfig';
import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const fetchData = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, { params });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const generateUserLabel = user => (
  <Flex alignItems="center" className="position-relative">
    <Avatar
      name={user.login}
      src={user.avatar_url}
      width="60"
      alt=""
      mediaClass="text-dark bg-soft-dark fs-0"
    />
    <div className="flex-1 ms-3">
      <h6 className="mb-0 fw-semi-bold">{user.login}</h6>
    </div>
  </Flex>
);

const AccountView = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  let inputValueKeyword = [];

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get(
          '/get-allowed-users-for-account/'
        );
        const users = response.data.allowed_users.map(user => ({
          value: user.id,
          userId: user.id,
          userLogin: user.login,
          userAvatar: user.avatar_url,
          label: generateUserLabel(user)
        }));
        setSelectedUsers(users);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Handle error
      }
    };

    fetchInitialData();
  }, []);
  const sleep = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));
  const loadOptions = async (inputValue, callback) => {
    console.log('loadOptions');
    inputValueKeyword = inputValue;

    await sleep(1000 + Math.floor(Math.random * 100));

    console.log('MAKE A REQUEST!!!!');
    try {
      const response = await fetchData('/search-users/', {
        keyword: inputValueKeyword
      });
      const users = response.items.map(user => ({
        value: user.id,
        userId: user.id,
        userLogin: user.login,
        userAvatar: user.avatar_url,
        label: generateUserLabel(user)
      }));
      callback(users);
    } catch (error) {
      console.error(error);
      callback([]);
    }
  };

  const onChange = data => {
    setSelectedUsers(data);
  };

  const sendSelectedUsers = async () => {
    setIsSaveLoading(true);
    try {
      const response = await axiosInstance.post(
        '/save-allowed-users-for-account/',
        {
          selectedUsers
        }
      );
      console.log('Data sent successfully:', response.data);
      // Display toast notification
      toast.success('Data saved successfully!', { position: 'bottom-right' });
    } catch (error) {
      console.error('Error sending data:', error);
      // Display toast notification
      toast.error('Error saving data!', { position: 'bottom-right' });
    }
    setIsSaveLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50"
          animation="border"
          size="xl"
        />
      ) : (
        <>
          <Row className="g-3 mb-5">
            <Col md={12} xxl={12}>
              <h2>My Account</h2>
              <h3>GitHub Accounts</h3>
              <p>
                Add GitHub accounts to invite them and gain access to the
                application. Each GitHub user can only access one Gitlights
                account. Therefore, to invite a user, they must first be removed
                from any other Gitlights accounts where they are currently
                registered.
              </p>

              <AsyncSelect
                isMulti
                onChange={onChange}
                defaultOptions
                cacheOptions
                isSearchable
                classNamePrefix="react-select"
                placeholder="Type here"
                loadOptions={loadOptions}
                value={selectedUsers}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {isSaveLoading ? (
                <Spinner
                  animation="border"
                  role="status"
                  className="my-accounts-save-users"
                ></Spinner>
              ) : (
                <button
                  onClick={sendSelectedUsers}
                  className="btn btn-primary mt-3 my-accounts-save-users"
                >
                  Save
                </button>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AccountView;
