import PropTypes from 'prop-types';
import React from 'react';
import CountUp from 'react-countup';

const AdvanceCountUp = ({ dataset }) => {
  return (
    <>
      <CountUp
        start={0}
        end={dataset}
        duration={2.75}
        suffix={''}
        prefix={''}
        decimals={0}
        decimal=","
        separator="."
      />
    </>
  );
};

AdvanceCountUp.propTypes = {
  dataset: PropTypes.number.isRequired // Adjust the type and validation as needed
};

export default AdvanceCountUp;
