import React, { forwardRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DashboardFiltersContext } from 'context/Context';
const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="position-relative">
    <Form.Control
      size="sm"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={e => {
        console.log({ e });
      }}
    />
    <FontAwesomeIcon
      icon="calendar-alt"
      className="text-primary position-absolute top-50 translate-middle-y ms-2"
    />
  </div>
));

const FilterDateInput = () => {
  const { setFilters } = useContext(DashboardFiltersContext);

  // Set the initial date to 15 days ago
  const fifteenDaysAgo = new Date();
  fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 90);

  const [startDate, setStartDate] = useState(
    localStorage.getItem('filterStartDate') !== null
      ? new Date(localStorage.getItem('filterStartDate'))
      : fifteenDaysAgo
  );
  const [endDate, setEndDate] = useState(
    localStorage.getItem('filterEndDate') !== null
      ? new Date(localStorage.getItem('filterEndDate'))
      : new Date()
  );

  const onChange = dates => {
    const [start, end] = dates;
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    localStorage.setItem('filterStartDate', dateStart);
    localStorage.setItem('filterEndDate', dateEnd);
    const formattedStartDate =
      dateStart.getDate() +
      '/' +
      parseInt(dateStart.getMonth() + 1) +
      '/' +
      dateStart.getFullYear();

    const formattedEndDate =
      dateEnd.getDate() +
      '/' +
      parseInt(dateEnd.getMonth() + 1) +
      '/' +
      dateEnd.getFullYear();
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setFilters({
        id: 50,
        startDate: formattedStartDate,
        endDate: formattedEndDate
      });
    }
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      formatWeekDay={day => day.slice(0, 3)}
      endDate={endDate}
      selectsRange
      monthsShown={2}
      dateFormat="MMM dd"
      customInput={<DateInput />}
    />
  );
};

DateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func
};

export default FilterDateInput;
