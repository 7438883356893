import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import LmsStatItem from './LmsStatItem';
const MainKPI = ({ data }) => {
  const className = [
    'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0',
    'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0',
    'border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0',
    'pt-4 p-xxl-0 pb-0 pe-md-0'
  ];
  const color = ['primary', 'info', 'success', 'warning'];
  const icon = ['code', 'code-branch', 'comment', 'database'];

  return (
    <Card className="mb-3">
      <Card.Body className="px-xxl-0 pt-4">
        <Row className="g-0">
          {data.map(
            (stat, index) => (
              (stat.className = className[index]),
              (stat.color = color[index]),
              (stat.icon = icon[index]),
              (<LmsStatItem key={stat.id} stat={stat} />)
            )
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

MainKPI.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MainKPI;
