import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Image } from 'react-bootstrap';

const Process = ({
  icon,
  iconText,
  isFirst,
  color,
  title,
  description,
  image,
  inverse,
  children,
  icon2,
  color2,
  title2,
  description2
}) => {
  return (
    <Row
      className={classNames('flex-center', {
        'mt-7': !isFirst,
        'mt-8': isFirst
      })}
    >
      {image ? (
        <Col
          md={{ order: inverse ? 2 : 0, span: 6 }}
          lg={5}
          xl={4}
          className={classNames({ 'pe-lg-2': inverse, 'ps-lg-2': !inverse })}
        >
          <Image fluid className="px-2 px-md-0" src={image} alt="" />
        </Col>
      ) : (
        <Col md lg={5} xl={4} className="mt-4 mt-md-0">
          <h5 className={`text-${color2}`}>
            <FontAwesomeIcon icon={icon2} className="me-2" />
            {iconText}
          </h5>
          <h3>{title2}</h3>
          <p>{description2}</p>
          {children}
        </Col>
      )}

      <Col md lg={5} xl={4} className="mt-4 mt-md-0">
        <h5 className={`text-${color}`}>
          <FontAwesomeIcon icon={icon} className="me-2" />
          {iconText}
        </h5>
        <h3>{title}</h3>
        <p>{description}</p>
        <br></br>
        {children}
      </Col>
    </Row>
  );
};

Process.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  iconText: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.node,

  icon2: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  color2: PropTypes.string,
  title2: PropTypes.string,
  description2: PropTypes.string
};

export default Process;
