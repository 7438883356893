import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps
}) => {
  return (
    <div className="table-responsive scrollbar">
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {page.map((row, i) => {
            prepareRow(row);

            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  var avatar = '';
                  var avatarName = '';
                  var linkDevDetail = '';
                  var showAvatar = false;
                  if (cell.column.id === 'dev') {
                    const [name, avatarUrl] = cell.row.original.dev.split('|');
                    avatar = avatarUrl;
                    avatarName = name;
                    linkDevDetail = '/dashboard/developer?dev1=' + avatarName;
                    cell.row.values.dev = name;
                    showAvatar = true;
                  }
                  if (cell.column.id === 'repo') {
                    avatar = '';
                    avatarName = cell.row.original.repo;
                    showAvatar = true;
                  }
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {showAvatar ? (
                        <>
                          {/* <img className="avatarTableRow" src={avatar}></img> */}

                          <Flex
                            alignItems="center"
                            className="position-relative"
                          >
                            <Avatar
                              className="avatarTableRow"
                              name={avatarName}
                              src={avatar}
                              alt={avatarName}
                            />
                            <div className="flex-1 ms-3">
                              {linkDevDetail ? (
                                <h6 className="mb-0 fw-semi-bold">
                                  <Link
                                    className="text-dark stretched-link"
                                    to={linkDevDetail}
                                  >
                                    {avatarName}
                                  </Link>
                                </h6>
                              ) : (
                                <h6 className="mb-0 fw-semi-bold">
                                  {avatarName}
                                </h6>
                              )}

                              {/*<p className="fs--2 mb-0 text-500">
                                {avatarName}
                              </p> */}
                            </div>
                          </Flex>
                        </>
                      ) : (
                        <>{cell.render('Cell')}</>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable;
