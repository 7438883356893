import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import React from 'react';
import { Card } from 'react-bootstrap';
import BubbleChart from './BubbleChart';

const BubbleCloudTags = (data, { ...rest }) => {
  return (
    <Card {...rest} className="h-100">
      <FalconCardHeader
        light
        title="Trending Keywords"
        titleTag="h6"
        className="py-2"
        endEl={<CardDropdown />}
      />
      <Card.Body className="py-2 d-flex flex-center position-relative">
        <BubbleChart data={data.data} />
      </Card.Body>
    </Card>
  );
};

BubbleCloudTags.propTypes = {};

export default BubbleCloudTags;
