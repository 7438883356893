import React from 'react';
import gitlightsLogo from 'assets/img/gitlights-logo.png';
import githubLogoCat from 'assets/img/logos/github-cat.png';
import githubLogo from 'assets/img/logos/github.png';
import FaqJoinGithub from 'components/pages/faq/faq-alt/FaqAlt';
import { Button, Card, Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

const LoginPage = () => {
  return (
    <>
      <WidgetSectionTitle
        icon="exclamation-circle"
        title="Welcome to Gitlights"
        subtitle="Connect to your Github account and start to explore your data"
        transform="shrink-4"
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-center">
            <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error join-gitlights-github-img">
                <Row className="g-3 mb-3">
                  <Col md={5}>
                    <img width="150px" height="160px" src={gitlightsLogo} />
                  </Col>
                  <Col md={2}></Col>
                  <Col md={5}>
                    <img width="270px" height="150px" src={githubLogoCat} />
                  </Col>
                </Row>
              </div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Gitlights wants to connect to your Github account
              </p>
              <hr />
              <p>
                Make sure the address is correct and that the page hasn't moved.
                If you think this is a mistake.
              </p>

              <Button
                //onClick={handleLoading}
                className="github-button me-2 mb-1"
                variant="primary"
              >
                <a href="https://github.com/login/oauth/authorize?client_id=dc49380fd4435a3cbba2&scope=read:user%20user:email">
                  <img
                    width="25px"
                    height="25px"
                    className="github-button-logo"
                    src={githubLogo}
                    alt="Logo"
                  />
                  <span className="github-button-link">
                    Authenticate with <b>GitHub</b>
                  </span>
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <FaqJoinGithub />
        </Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};

export default LoginPage;
