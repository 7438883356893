import EcomStat from 'components/dashboards/e-commerce/EcomStat';
import PropTypes from 'prop-types';
import React from 'react';

const SixPackKPIs = ({ dataset }) => {
  console.log(dataset);
  const sixPackItems = [
    {
      title: dataset[0].key,
      amount: dataset[0].ammount,
      subAmount: dataset[0].subAmount,
      type: dataset[0].performance,
      icon: 'caret-' + dataset[0].performance,
      percent: dataset[0].percent,
      className: 'border-200 border-bottom border-end pb-4'
    },
    {
      title: dataset[1].key,
      amount: dataset[1].ammount,
      subAmount: dataset[1].subAmount,
      type: dataset[1].performance,
      icon: 'caret-' + dataset[1].performance,
      percent: dataset[1].percent,
      className:
        'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
    },
    {
      title: dataset[2].key,
      amount: dataset[2].ammount,
      subAmount: dataset[2].subAmount,
      type: dataset[2].performance,
      icon: 'caret-' + dataset[2].performance,
      percent: dataset[2].percent,
      className:
        'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3'
    },
    {
      title: dataset[3].key,
      amount: dataset[3].ammount,
      subAmount: dataset[3].subAmount,
      type: dataset[3].performance,
      icon: 'caret-' + dataset[3].performance,
      percent: dataset[3].percent,
      className:
        'border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0'
    },
    {
      title: dataset[4].key,
      amount: dataset[4].ammount,
      subAmount: dataset[4].subAmount,
      type: dataset[4].performance,
      icon: 'caret-' + dataset[4].performance,
      percent: dataset[4].percent,
      className: 'border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3'
    },
    {
      title: dataset[5].key,
      amount: dataset[5].ammount,
      subAmount: dataset[5].subAmount,
      type: dataset[5].performance,
      icon: 'caret-' + dataset[5].performance,
      percent: dataset[5].percent,
      className: 'pb-0 pt-4 ps-3'
    }
  ];
  return (
    <>
      <EcomStat data={sixPackItems} />
    </>
  );
};

SixPackKPIs.propTypes = {
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      ammount: PropTypes.number.isRequired,
      subAmount: PropTypes.number.isRequired,
      performance: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired
    })
  ).isRequired
};

export default SixPackKPIs;
