import ComboPieMultiLine from 'components/common/custom-charts/ComboPieMultiLine';
import Quadrant from 'components/common/custom-charts/Quadrant';
import InfoModal from 'components/common/custom-components/InfoModal';
import {
  DashboardFilterDevelopersContext,
  DashboardFilterRepositoriesContext,
  DashboardFiltersContext
} from 'context/Context';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
const InvestmentBalance = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [isLoading, setIsLoading] = useState(true);
  const filtersContext = useContext(DashboardFiltersContext);
  const developersFiltersContext = useContext(DashboardFilterDevelopersContext);

  const [investmentBalanceMultiChart, setInvestmentBalanceMultiChart] =
    useState([]);
  const [
    scatterChartCommitsVsLinesChanged,
    setScatterChartCommitsVsLinesChanged
  ] = useState([]);

  const [
    scatterChartLinesChangedVsCommitMessages,
    setScatterChartLinesChangedVsCommitMessages
  ] = useState([]);

  const repositoriesFiltersContext = useContext(
    DashboardFilterRepositoriesContext
  );

  useEffect(() => {
    setIsLoading(true);
    if (localStorage.getItem('waitingIBResponse') === null) {
      localStorage.setItem('waitingIBResponse', true);
      axiosInstance
        .get('/get-investment-balance-dashboard/', {
          params: {
            startDate: filtersContext.filters.startDate,
            endDate: filtersContext.filters.endDate,
            developers:
              developersFiltersContext.developersFilter.developers.toString(),
            repositories:
              repositoriesFiltersContext.repositoriesFilter.repositories.toString()
          }
        })
        .then(response => {
          const data = response.data.dashboard_data;
          setInvestmentBalanceMultiChart(data.investment_balance_multichart);
          setScatterChartCommitsVsLinesChanged(
            data.scatter_chart_commits_vs_lines_changed
          );
          setScatterChartLinesChangedVsCommitMessages(
            data.scatter_chart_lines_changed_vs_commit_messages
          );
          setIsLoading(false);
          localStorage.removeItem('waitingIBResponse');
        })
        .catch(error => {
          localStorage.removeItem('waitingIBResponse');
          console.error(error);
        });
    } else {
      localStorage.removeItem('waitingIBResponse');
    }
  }, [filtersContext, developersFiltersContext, repositoriesFiltersContext]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Investment Balance and Temporal Evolution',
                      description: `This widget offers a detailed analysis of the investment balance in software development, 
      supported by a sophisticated algorithm based on <strong>natural language processing (NLP)</strong> 
      and <strong>artificial intelligence (AI)</strong> technologies. The categorization of commits is done 
      precisely, considering not only the commit message but also other crucial variables, such as lines 
      of code added, deleted, and changes in files.<br /><br />
      
      The algorithm assigns weights to different key areas of development, and the pie chart displays the 
      relative distribution of investments in specific categories. Additionally, the stacked bar chart offers 
      a visual representation of the investment balance over time, revealing the strategic evolution of the 
      project. This advanced process provides insights into the current project focus, spanning from new 
      implementations to maintenance and testing, supporting data-driven decision-making based on key software 
      development variables. An innovative and holistic approach to effective software development investment management.<br /><br />
      
      <strong>Categories:</strong>
      <ul>
        <li><strong>New Development:</strong> Investments in creating and developing new features, algorithms, 
          and significant improvements. Examples include implementing innovative features and creating solutions 
          from scratch. Add the hashtag #feature to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>Refactoring:</strong> Investments aimed at optimizing and improving the structure and efficiency 
          of existing code. This may include code cleanup, performance improvements, and modernization. Add the 
          hashtag #refactor to the commit message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Fixes and Maintenance:</strong> Investments directed at addressing issues and defects, as well 
          as making updates and patches to enhance stability and correct errors. Add the hashtag #fix to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Testing and QA:</strong> Investments dedicated to ensuring software quality through thorough 
          testing, ensuring correct functionality, and system integrity. Add the hashtag #test to the commit message 
          to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Upgrades:</strong> Investments in the continuous improvement of software through updates, 
          introducing new features, and enhancing the user experience. Add the hashtag #upgrade to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Security and Compliance:</strong> Investments focused on implementing security measures, 
          addressing vulnerabilities, and complying with standards and regulations. Add the hashtag #security to the 
          commit message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Documentation:</strong> Investments aimed at creating and updating documentation, including README 
          files, comments, and manuals. Add the hashtag #doc to the commit message to force the assignment of this 
          category and skip the AI algorithm.</li>
        <li><strong>Performance Optimization:</strong> Investments to improve system efficiency and speed, including 
          optimization of database queries and algorithm enhancements. Add the hashtag #performance to the commit 
          message to force the assignment of this category and skip the AI algorithm.</li>
        <li><strong>Code Review:</strong> Investments in code review and analysis to ensure the quality and consistency 
          of the source code. Add the hashtag #review to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>Dependency Management:</strong> Investments related to the management and update of dependencies and 
          external libraries. Add the hashtag #dependency to the commit message to force the assignment of this category 
          and skip the AI algorithm.</li>
        <li><strong>CI/CD:</strong> Investments in the implementation and continuous improvement of Continuous Integration 
          (CI) and Continuous Deployment (CD) processes to streamline development and deployment. Add the hashtag #cicd to 
          the commit message to force the assignment of this category and skip the AI algorithm.</li>
      </ul><br />
      The presented data belongs to the subset of filters applied in the header.<br />`
                    }}
                  />
                  <ComboPieMultiLine dataset={investmentBalanceMultiChart} />
                </Col>
              </Row>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Commit Density in Relation to Code Line Balance',
                      description: `
                        This graph provides a detailed visualization of commit density based on the balance of code lines 
                        (lines added - lines deleted). The resulting representation tends to form a Gaussian distribution with 
                        a bell-shaped curve, characterized by a peak at the mean of the code line balance.
                        <br /><br />
                        <strong>Key Features:</strong>
                        <ul>
                          <li><strong>Gaussian Distribution:</strong> The bell-shaped curve reveals the distribution of commits in 
                          relation to the balance of code lines. A peak at the mean indicates the most frequent value of the balance, 
                          offering insights into predominant trends.</li>
                          <li><strong>Interpretation of Key Moments:</strong> It allows identification of whether the organization is 
                          in a phase of introducing new code to the project, typically associated with the development of new features, 
                          products, or services. Additionally, it provides insights into moments of project size reduction, indicating 
                          refactors, software improvements, and sometimes bug-fixing periods.</li>
                          <li><strong>Strategic Utility:</strong> Serves as a valuable tool to understand the dynamics of development 
                          in the organization, facilitating pattern identification and strategic decision-making. The position and shape 
                          of the bell curve reveal the direction and intensity of ongoing development activities.</li>
                        </ul>
                        <br />
                        This component provides a powerful graphical representation that helps leaders and development teams gain a clear 
                        view of current trends in the project lifecycle, contributing to better planning and software development management.
                      `
                    }}
                  />
                  <Quadrant
                    dataset={scatterChartCommitsVsLinesChanged}
                    type="line"
                  />
                </Col>
              </Row>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Commit Density Based on Commit Message Size',
                      description: `
                        The following line depicts commit density based on the size of commit messages. This graph is interpreted as the 
                        team's ability to document changes based on how explicit they are when reporting their changes. Organizations
                         with clear, explicit, and well-structured commit messages position themselves for a better understanding of 
                         everyone's work.
                        <br /><br />
                        <strong>Key Insights:</strong>
                        <ul>
                          <li><strong>Commit Message Size Influence:</strong> The line graph illustrates how the density of commits varies 
                          with the size of commit messages. It reflects the team's commitment to providing comprehensive and informative 
                          documentation for their changes.</li>
                          <li><strong>Documentation Quality Indicator:</strong> Commit messages serve as a documentation tool, and this 
                          graph acts as an indicator of the organization's commitment to clear and detailed communication. Well-documented changes contribute to a shared understanding of the team's work.</li>
                          <li><strong>Team Communication Impact:</strong> Explicit and well-structured commit messages enhance 
                          communication within the team, fostering a culture of transparency and clarity. This, in turn, contributes to a 
                          more effective and collaborative development environment.</li>
                        </ul>
                        <br />
                        This component provides a visual representation that enables teams and leaders to assess the quality of commit 
                        documentation. Clear and detailed commit messages not only enhance the understanding of individual changes but also 
                        contribute to the overall cohesion and productivity of the development team.
                      `
                    }}
                  />
                  <Quadrant
                    dataset={scatterChartLinesChangedVsCommitMessages}
                    type="line"
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default InvestmentBalance;
