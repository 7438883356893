import gitlightsLogo from 'assets/img/gitlights-logo.png';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';

export const checkIsDataLoaded = () => {
  var storedUserData = localStorage.getItem('userData');
  var parsedUserData = JSON.parse(storedUserData);

  const data_loaded_for_organization =
    parsedUserData.data_loaded_for_organization;
  return data_loaded_for_organization;
};

export const EmptyPage = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <>
      <WidgetSectionTitle
        icon="exclamation-circle"
        title="Welcome to Gitlights"
        subtitle="Your Analytics Data Isn't Accessible Yet"
        transform="shrink-4"
        className="mb-4 mt-6"
      />
      <Row className="g-3 mb-3">
        <Col md={3}></Col>
        <Col md={6}>
          <Card className="text-center">
            <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error join-gitlights-github-img">
                <Row className="g-3 mb-3">
                  <Col md={12}>
                    <img
                      width="150px"
                      height="160px"
                      src={gitlightsLogo}
                      alt="Gitlights Logo"
                    />
                  </Col>
                </Row>
              </div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                We are loading your data!!
              </p>
              <hr />
              <p>
                We're busy collecting your data to provide you with valuable
                insights. Please refresh this page in some minutes to access
                your analytics.
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col md={10}></Col>
        <Col md={1}></Col>
      </Row>
    </>
  );
};
