import Section from 'components/common/Section';
import PricingDefault from 'components/pages/pricing/pricing-default/PricingDefault';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Features from './Processes';
import Services from './Services';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      {/* <Partners /> */}
      <div id="features">
        <Features />
      </div>
      <div id="get_started">
        <Services />
      </div>
      {/* <Testimonial /> */}
      <Cta />
      <Section bg="light" className="text-center">
        <div id="pricing" className="justify-content-center text-center row">
          <div className="col-lg-8">
            <h1 className="fs-2 fs-sm-4 fs-md-5">
              Select your plan after 15-day free trial
            </h1>
            <p className="lead">
              Choose a plan according to the number of contributors in your
              organization. If you select a plan with fewer contributors than
              you have, there's no problem. However, keep in mind that in that
              case, only the N contributors with the most recent commit activity
              will be shown daily (where N is the number of contributors in your
              plan). For example, if you have 100 developers making commits in
              your organization but choose the Lite plan for 10 contributors,
              only the 10 developers with the most recent activity will appear
              in the metrics each day, losing visibility of the rest. All plans
              have the same features.
            </p>
          </div>
        </div>

        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <PricingDefault isPurchasable={false} />
          </Col>
          <Col lg={1}></Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default Landing;
