import classNames from 'classnames';
import FilterDateInput from 'components/common/global-components/FilterDateInput';
import FilterDevelopersInput from 'components/common/global-components/FilterDevelopersInput';
import FilterRepositoriesInput from 'components/common/global-components/FilterRepositoriesInput';
import Logo from 'components/common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';

const NavbarTop = () => {
  const {
    config: { showBurgerMenu, navbarPosition, navbarCollapsed },
    setConfig
  } = useContext(AppContext);

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    (navbarPosition === 'top' || navbarPosition === 'double-top') &&
      setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  const burgerMenuRef = useRef();

  return (
    <Navbar
      className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
        // 'navbar-glass-shadow': showDropShadow
        'navbar-glass-shadow': showDropShadow && !isChat
      })}
      expand={
        navbarPosition === 'top' ||
        navbarPosition === 'combo' ||
        navbarPosition === 'double-top'
          ? topNavbarBreakpoint
          : true
      }
    >
      {navbarPosition === 'double-top' ? (
        <div className="w-100">
          <div className="d-flex flex-between-center">
            <NavbarTopElements
              navbarCollapsed={navbarCollapsed}
              navbarPosition={navbarPosition}
              handleBurgerMenu={handleBurgerMenu}
              burgerMenuRef={burgerMenuRef}
            />
          </div>
          <hr className="my-2 d-none d-lg-block" />
          <Navbar.Collapse in={navbarCollapsed} className="scrollbar py-2">
            <Nav navbar>
              <NavbarTopDropDownMenus />
            </Nav>
          </Navbar.Collapse>
        </div>
      ) : (
        <NavbarTopElements
          navbarCollapsed={navbarCollapsed}
          navbarPosition={navbarPosition}
          handleBurgerMenu={handleBurgerMenu}
          burgerMenuRef={burgerMenuRef}
        />
      )}
    </Navbar>
  );
};

const NavbarTopElements = ({
  navbarPosition,
  handleBurgerMenu,
  navbarCollapsed
}) => {
  const burgerMenuRef = useRef();
  var showDatesFilter = false;
  var showDevelopersFilter = false;
  var showRepositoriesFilter = false;

  var pathname = window.location.pathname;

  if (pathname == '/dashboard/main') {
    showDatesFilter = false;
  } else if (pathname == '/dashboard/commits') {
    showDatesFilter = true;
    showDevelopersFilter = true;
    showRepositoriesFilter = true;
  } else if (pathname == '/dashboard/prs') {
    showDatesFilter = true;
    showDevelopersFilter = true;
    showRepositoriesFilter = true;
  } else if (pathname == '/dashboard/developer') {
    showDatesFilter = true;
    showRepositoriesFilter = false;
  } else if (pathname == '/dashboard/developers') {
    showDatesFilter = false;
    showRepositoriesFilter = false;
  } else if (pathname == '/dashboard/investment-balance') {
    showDatesFilter = true;
    showDevelopersFilter = true;
    showRepositoriesFilter = true;
  }
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none':
            navbarPosition === 'top' || navbarPosition === 'double-top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>

      <Logo at="navbar-top" width={40} id="topLogo" />
      <Col md={11}>
        {navbarPosition === 'top' || navbarPosition === 'combo' ? (
          <Navbar.Collapse
            in={navbarCollapsed}
            className="scrollbar pb-3 pb-lg-0"
          >
            <Nav navbar>
              <NavbarTopDropDownMenus />
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Nav
            navbar
            className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
            as="ul"
          >
            <Col md="auto" className="mb-3 mb-md-0">
              <Row className="g-3 gy-md-0 h-100 align-items-center">
                {showDatesFilter ? (
                  <>
                    <Col md={1}>
                      <h6 className="text-700 mb-0 text-nowrap">Dates:</h6>
                    </Col>
                    <Col className="colWithMin20percent" md="auto">
                      <FilterDateInput />
                    </Col>
                  </>
                ) : undefined}
                {showDevelopersFilter ? (
                  <>
                    <Col md={1}>
                      <h6 className="text-700 mb-0 text-nowrap">Developers:</h6>
                    </Col>
                    <Col className="colWithMin20percent" md="auto">
                      <FilterDevelopersInput />
                    </Col>
                  </>
                ) : undefined}
                {showRepositoriesFilter ? (
                  <>
                    <Col md={1}>
                      <h6 className="text-700 mb-0 text-nowrap">
                        Repositories:
                      </h6>
                    </Col>
                    <Col className="colWithMin20percent" md="auto">
                      <FilterRepositoriesInput />
                    </Col>
                  </>
                ) : undefined}
              </Row>
            </Col>
          </Nav>
        )}
      </Col>
      <Col className="profileDropdownAvatar" md={1}>
        {' '}
        <TopNavRightSideNavItem />
      </Col>
    </>
  );
};

NavbarTopElements.propTypes = {
  navbarPosition: PropTypes.string,
  handleBurgerMenu: PropTypes.func,
  navbarCollapsed: PropTypes.bool
};
export default NavbarTop;
