import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: `What is the purpose of Gitlights?`,
    description: `Gitlights is a trusted application that connects to the GitHub API to obtain relevant metrics and key performance indicators (KPIs) for development performance. Our aim is to provide valuable insights to enhance your team's productivity and efficiency.`
  },
  {
    id: 1,
    title: 'How does Gitlights prioritize data privacy?',
    description: `At Gitlights, we prioritize data privacy and security. We strictly use the data we collect from your GitHub account solely for the purpose of generating performance metrics. We do not share this information with any third parties and ensure it is securely stored on our servers using industry-standard security measures.`
  },
  {
    id: 2,
    title: 'Does Gitlights have access to our source code?',
    description: `No, Gitlights does not have access to or read your source code. We respect the confidentiality of your intellectual property. Our focus is on analyzing metadata such as commits, branches, pull requests, comments, reviews, and pull request states to provide performance insights without accessing your source code.`
  },
  {
    id: 3,
    title: `What data does Gitlights access through the GitHub API?`,
    description: `Gitlights accesses specific data through the GitHub API, including commit history, branch details, pull request information, comments, code reviews, and pull request statuses. This information is essential for generating accurate KPIs related to your development processes and is handled securely within our platform.`
  },
  {
    id: 4,
    title: `Can we customize the metrics tracked by Gitlights?`,
    description: `Gitlights offers flexible data update frequencies based on your preferences. By default, we can pull data from your GitHub account daily or hourly, providing near real-time insights into your development performance. You can customize the update frequency according to your requirements.`
  },
  {
    id: 5,
    title: `Can we revoke permissions or disconnect our GitHub account from Gitlights?`,
    description: `Yes, you have complete control over the permissions granted to Gitlights. If at any time you decide to revoke access or disconnect your GitHub account from our app, you can do so effortlessly. This ensures that Gitlights will no longer retrieve any data from your GitHub repositories.`
  },
  {
    id: 6,
    title: `How can we trust Gitlights and receive support if needed?`,
    description: `Gitlights is committed to building and maintaining trust with our customers. We have implemented robust security measures, maintain strict privacy policies, and comply with industry standards. Should you require any assistance or have any concerns, our dedicated support team is readily available to address your questions and provide prompt support.`
  },
  {
    id: 7,
    title: `Can I remove my Gitlights account and what happens to my data?`,
    description: `Absolutely! You have the option to remove your Gitlights account at any time. When you initiate the account removal process, all your historical data stored on our servers will be permanently deleted. We prioritize data privacy and ensure that your information is completely wiped from our system upon account removal.`
  },
  {
    id: 8,
    title: ` What happens to my subscription if I remove my Gitlights account?`,
    description: `When you remove your Gitlights account, your subscription will be canceled without any extra charges. We believe in providing a seamless experience, which includes the flexibility to manage your subscription according to your needs. You can rest assured that no additional fees will be incurred once your account is removed.`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'How long do payouts take?',
    description:
      '<p class = "mb-0">Once you’re set up, payouts arrive in your bank account on a 2-day rolling basis. Or you can opt to receive payouts weekly or monthly.</p>'
  },
  {
    id: 1,
    title: 'How do refunds work?',
    description:
      '<p class = "mb-0">You can issue either partial or full refunds. There are no fees to refund a charge, but the fees from the original charge are not returned.</p>'
  },
  {
    id: 2,
    title: 'How much do disputes costs?',
    description:
      '<p class = "mb-0">Disputed payments (also known as chargebacks) incur a $15.00 fee. If the customer’s bank resolves the dispute in your favor, the fee is fully refunded.</p>'
  },
  {
    id: 3,
    title: 'Is there a fee to use Apple Pay or Google Pay?',
    description:
      '<p class = "mb-0">There are no additional fees for using our mobile SDKs or to accept payments using consumer wallets like Apple Pay or Google Pay.</p>'
  }
];

export const faqDoc = [
  {
    id: 1,
    title: 'How to start working with Falcon React',
    description: `<p class='mb-0'>Install latest version of Node js to your PC <a href='https://nodejs.dev/' target="_blank">https://nodejs.dev/</a> Download the template file and unzip it.
    You will get 2 files in the folder. Now unzip the <code>falcon-react-${version}</code> directory and open this directory from your terminal.
    Run <code>npm install </code> from your terminal.
    After installing all the required packages, run <code>npm start</code>. <br>
    Please check the following link to see How to Getting started in Falcon React: <a target='_blank' href='//${homepage}/documentation/getting-started'>${homepage}/documentation/getting-started<a/>.</p>`
  },
  {
    id: 2,
    title:
      'Is there a specific version of Node.js I should use for Falcon React?',
    description: `<p class="mb-0">Node version to <code>16.15.1</code> or any further updated version.</p>`
  },

  {
    id: 3,
    title: 'What React library is used for general API integration?',
    description: `<p class="mb-0">We used react context API for general API integration.</p>`
  },
  {
    id: 4,
    title: 'How are the SEO headers built?',
    description: `<p class="mb-0">We didn't build with any SEO headers by default. You have to integrate it manually by yourself.</p>`
  },
  {
    id: 5,
    title:
      'Which bootstrap react framework you are using? Reactstrap or React-Bootstrap?',
    description: `<p class="mb-0">We used the <b>reactstrap</b> for Falcon React <code>v2.x</code> and <b>React bootstrap</b> for Falcon React <code>v3.x</code>.</p>`
  },
  {
    id: 6,
    title: 'Where will I get the Figma Files?',
    description: `<p >You will find the Figma link in the <code>README.md</code> file on your downloaded copy of Falcon. Here is the detailed documentation: <a target='_blank' href='//${homepage}/documentation/design-file'> ${homepage}/documentation/design-file</a>.</p>
    <p class='mb-0'>If you still have any problem with the link, reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a></p>`
  },
  {
    id: 7,
    title:
      'What are the bare minimum files just to get the layout and navigation?',
    description: `<p >You can start from the Falcon’s Starter page where the minimum required components are included to get layout and navigation.
    More specifically, the below list of files are required to work with layout and navigation:<br>
    <code>src/assets</code><br>
    <code>src/components/common</code><br>
    <code>src/components/footer</code><br>
    <code>src/components/navbar</code><br>
    <code>src/components/notification</code><br>
    <code>src/components/pages/Starter.js</code><br>
    <code>src/components/settings-panel</code><br>
    <code>src/context</code><br>
    <code>src/data</code><br>
    <code>src/helpers</code><br>
    <code>src/hooks</code><br>
    <code>src/layouts/Layout.js</code><br>
    <code>src/layouts/MainLayout.js</code><br>
    <code>src/layouts/MainRoutes.js</code><br>
    <code>src/reducers</code><br>
    <code>src/routes</code><br>
    <code>src/App.js</code><br>
    <code>src/Config.js</code><br>
    <code>src/Index.js</code><br>
    <code>src/Main.js</code></p>

    <p>Note:  Remember deleting other files/directories will be the cause of errors. You have to remove the corresponding import statement and unnecessary lines from your code manually.
    </p>
    <p class="mb-0">We have created a mini version of Falcon React with all the essential files only. If you are interested, please reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a>.
    </p>`
  },
  {
    id: 8,
    title: 'How to compile SCSS files with Falcon React?',
    description: `<p class="mb-0">To compile scss files in Falcon react run npm run <code>build:css</code> from your terminal, or you can watch the scss files by running npm run <code>watch:css</code> to a separate terminal. 
    Check the link below to have a look on the styling documentation in Falcon React <a target='_blank' href='//${homepage}/documentation/styling'> ${homepage}/documentation/styling</a>
    </p>`
  },
  {
    id: 9,
    title: 'How to update from Falcon React v2.x to the upper version?',
    description: `<p><b>Updating from any earlier version of 3.x to ${version}:</b></br>
    Check out the following link and follow the changes: <a target='_blank' href='//${homepage}/changelog'> ${homepage}/changelog</a>
    </p>
    <p class='mb-0'><b>Updating from version 2 to version 3:</b></br>
    As the latest versions of templates and all their packages have different structures behind their components, you have to update all the components manually into your project.<br>
    So, it would be better to start building applications with the Bootstrap 5 version of Falcon from scratch. You can include any components from your existing project to your current project. Bring the component and its required SCSS and place it into your current project.
    </p>`
  }
];
