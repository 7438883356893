import AdvanceSearchTable from 'components/common/custom-charts/AdvanceSearchTable';
import LabeledCountUp from 'components/common/custom-charts/LabeledCountUp';
import MultiLineStackHistogramV2 from 'components/common/custom-charts/MultiLineStackHistogram';
import SixPackKPIs from 'components/common/custom-charts/SixPackKPIs';
import SmallPieChart from 'components/common/custom-charts/SmallPieChart';
import InfoModal from 'components/common/custom-components/InfoModal';
import {
  DashboardFilterDevelopersContext,
  DashboardFilterRepositoriesContext,
  DashboardFiltersContext
} from 'context/Context';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

const CommitsView = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());
  const [commitsByRepo, setCommitsByRepo] = useState([]);
  const [commitsSixPackKPIs, setCommitsSixPackKPIs] = useState([]);
  const [commitInfoByDevTable, setCommitInfoByDevTable] = useState([]);
  const [commitInfoByRepoTable, setCommitInfoByRepoTable] = useState([]);
  const [linesAddedDeletedHistogram, setLinesAddedDeletedHistogram] = useState(
    []
  );
  const [CommitsEvolutionRSIEMA, setCommitsEvolutionRSIEMA] = useState([]);
  const [linesAdded, setLinesAdded] = useState([]);
  const [linesDeleted, setLinesDeleted] = useState([]);
  const [linesBalance, setLinesBalance] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const filtersContext = useContext(DashboardFiltersContext);
  const developersFiltersContext = useContext(DashboardFilterDevelopersContext);
  const repositoriesFiltersContext = useContext(
    DashboardFilterRepositoriesContext
  );

  useEffect(() => {
    setIsLoading(true);
    // Fetch the trending keywords from the backend endpoint
    if (localStorage.getItem('waitingPRsResponse') === null) {
      localStorage.setItem('waitingPRsResponse', true);
      axiosInstance
        .get('/get-commits-dashboard/', {
          params: {
            startDate: filtersContext.filters.startDate,
            endDate: filtersContext.filters.endDate,
            developers:
              developersFiltersContext.developersFilter.developers.toString(),
            repositories:
              repositoriesFiltersContext.repositoriesFilter.repositories.toString()
          }
        })
        .then(response => {
          const data = response.data.dashboard_data;

          setLinesAdded(data.linesAdded);
          setLinesDeleted(data.linesDeleted);
          setLinesBalance(data.linesBalance);
          setCommitsByRepo(data.commits_by_repo);
          setCommitsSixPackKPIs(data.commits_six_pack_kpis);
          setCommitInfoByDevTable(data.commits_info_by_dev_table);
          setCommitInfoByRepoTable(data.commits_info_by_repo_table);
          setLinesAddedDeletedHistogram(data.lines_added_deleted_histogram);
          setCommitsEvolutionRSIEMA(data.commits_evolution_RSI_EMA);

          setIsLoading(false);
          localStorage.removeItem('waitingPRsResponse');
        })
        .catch(error => {
          localStorage.removeItem('waitingPRsResponse');
          console.error(error);
        });
    } else {
      localStorage.removeItem('waitingPRsResponse');
    }
  }, [filtersContext, developersFiltersContext, repositoriesFiltersContext]);

  return (
    <>
      {isDataLoaded ? (
        <>
          {isLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              <Row className="g-3 mb-6">
                <Col md={12} xxl={12}>
                  <InfoModal
                    dataset={{
                      title: 'Evolution of Commits with EMA and RSI',
                      description: `
                    <p>This widget offers a detailed visual representation of the historical evolution of commits. The purple bars reflect the absolute values of commits made in specific periods, providing a clear view of the activity. For a smoother interpretation, the blue bars reveal the Exponential Moving Average (EMA) of commits, emphasizing trends over time.</p>

                    <p>Additionally, a green line is incorporated to represent the Relative Strength Index (RSI), a key indicator used in various sectors. In the context of Git development, the RSI offers insight into the team's fitness in terms of the frequency of commits. Both EMA and RSI act on the last 4 samples, providing a more accurate view of recent trends.</p>

                    <h5>Benefits and Possible Interpretations:</h5>
                    <ul>
                      <li><strong>Trend Identification:</strong> The EMA provides a smoothed representation of commit trends over time, aiding in the identification of overarching patterns in the team's contribution. Sudden spikes or declines in EMA may indicate shifts in development momentum.</li>
                      <li><strong>Strength of Development Momentum:</strong> The RSI serves as an indicator of the team's development momentum. A consistently high RSI may suggest a sustained high level of commit activity, while a declining RSI could signify a potential slowdown.</li>
                      <li><strong>Decision Support for Planning:</strong> The combination of EMA and RSI aids in decision-making for future development planning. Teams can use this information to anticipate periods of high or low activity, allowing for more effective resource allocation and project planning.</li>
                    </ul>

                    <p>Overall, this type of chart serves as a powerful tool for project managers, team leads, and developers alike, offering insights that contribute to informed decision-making, improved collaboration, and efficient resource utilization in the Git development environment.</p>
                  `
                    }}
                  />

                  <MultiLineStackHistogramV2
                    dataset={CommitsEvolutionRSIEMA}
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
              </Row>
              <Row className="g-5 mb-3">
                <Col
                  className="multi-stack-histogram-lines-of-code"
                  md={9}
                  xxl={9}
                >
                  <InfoModal
                    dataset={{
                      title:
                        'Evolution of Code Lines (Added, Deleted, and Balance)',
                      description: `
                        This stacked bar chart provides a detailed view of the temporal evolution of added and deleted lines of code, as well as the balance between them. 
                        Each bar represents the net balance between added and deleted lines in a specific period. This visual approach allows determining whether the development team is 
                        in a phase more focused on new developments or on refactoring and maintenance activities. The presented data is part of the subset of filters applied in the header, 
                        facilitating the adaptation of the visualization to specific analysis criteria.
                      `
                    }}
                  />
                  <MultiLineStackHistogramV2
                    dataset={linesAddedDeletedHistogram}
                    feature={{
                      dataView: { show: false },
                      magicType: {
                        show: true,
                        type: ['stack', 'tiled']
                      },
                      restore: { show: true },
                      saveAsImage: { show: true }
                    }}
                  />
                </Col>
                <Col md={3} xxl={3}>
                  <Row className="g-3 mb-3">
                    <InfoModal
                      dataset={{
                        title: 'Commits by Repository',
                        description: `
                          This simple pie chart offers a clear and concise view of the number of commits per repository.
                           Each pie segment represents the proportion of commits for a specific repository. 
                           These data, collected from the subset of filters applied in the header, provide a quick snapshot of the distribution of development activity among different repositories.
                        `
                      }}
                    />
                    <SmallPieChart
                      dataset={commitsByRepo}
                      radius={['100%', '87%']}
                    />
                  </Row>
                  <Row className="g-3 mb-3">
                    <InfoModal
                      dataset={{
                        title: 'Added, Deleted, and Balance Lines',
                        description: `
                          These indicators present the absolute value of added lines, deleted lines, and the net balance between them. 
                          The data, extracted from the subset of filters applied in the header, offers a detailed view of development activity. 
                          By providing key metrics of code lines, this widget allows a quick and accurate assessment of the impact of changes in the code. 
                          Customizing data according to the filters applied in the header ensures a specific and relevant representation for the ongoing analysis.
                        `
                      }}
                    />
                    <LabeledCountUp dataset={linesAdded} icon="plus" />
                    <LabeledCountUp dataset={linesDeleted} icon="minus" />
                    <LabeledCountUp dataset={linesBalance} icon="equals" />
                  </Row>
                </Col>
              </Row>

              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: " Repositories' Indicators Table",
                    description: `
                      This table displays the key indicators linked to commits and is grouped by repository. You can use the search function or pagination to find information about a specific repository. The presented data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Total Commits:</strong> The total number of commits made in each repository.</li>
                        <li><strong>Commits by Day:</strong> The distribution of commits over days for each repository.</li>
                        <li><strong>Additions per Commit:</strong> The average number of lines added per commit in a repository, indicating the magnitude of changes in each contribution.</li>
                        <li><strong>Deletions per Commit:</strong> The average number of lines deleted per commit in a repository, highlighting the amount of code eliminated in each change.</li>
                        <li><strong>Files Changed per Commit:</strong> The average number of files modified in each commit in a repository, offering information about the complexity of the modifications.</li>
                        <li><strong>Message Size per Commit:</strong> The average size of messages associated with each commit in a repository, providing insights into code documentation and communication.</li>
                      </ul>
                    `
                  }}
                />
                <AdvanceSearchTable dataset={commitInfoByDevTable} />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: " Developers' Indicators Table",
                    description: `
                      This table presents the key indicators related to commits grouped by developer. Using the search function or pagination, you can find specific information about a developer. Clicking on any of the indicators redirects to the developer's detailed page. The included data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Total Commits:</strong> The total number of commits made by each developer.</li>
                        <li><strong>Commits by Day:</strong> The distribution of commits over days, providing a view of each developer's daily activity.</li>
                        <li><strong>Additions per Commit:</strong> The average number of lines added per commit, indicating the magnitude of changes in each contribution.</li>
                        <li><strong>Deletions per Commit:</strong> The average number of lines deleted per commit, highlighting the amount of code removed in each change.</li>
                        <li><strong>Files Changed per Commit:</strong> The average number of files modified in each commit, offering information about the extent of the modifications.</li>
                        <li><strong>Message Size per Commit:</strong> The average size of messages associated with each commit, providing insights into the quality and clarity of change descriptions.</li>
                      </ul>
                    `
                  }}
                />
                <AdvanceSearchTable dataset={commitInfoByRepoTable} />
              </Row>

              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title:
                      'Comparison of Indicators with the Average of Other Organizations',
                    description: `
                      This component compares the key indicators related to commits with the average of other organizations. It also shows the upward or downward trend and the percentage of variation. This component is useful for obtaining a reference for each metric outside your organization. The indicators are agnostic to both the number of developers and the size over time of the analyzed sample. The presented data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Average Commit Message Size:</strong> The average size of commit messages compared to the average of other organizations.</li>
                        <li><strong>Ratio Added/Deleted Lines of Code:</strong> The ratio between lines added and lines deleted compared to the average of other organizations.</li>
                        <li><strong>Commits per Developer per Day:</strong> The number of commits per developer per day compared to the average of other organizations.</li>
                        <li><strong>Files Changed per Commit:</strong> The average number of files modified per commit compared to the average of other organizations.</li>
                        <li><strong>Lines Added per Developer:</strong> The number of lines added per developer compared to the average of other organizations.</li>
                        <li><strong>Lines Deleted per Developer:</strong> The number of lines deleted per developer compared to the average of other organizations.</li>
                      </ul>
                    `
                  }}
                />
                <SixPackKPIs dataset={commitsSixPackKPIs} />
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default CommitsView;
