import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const columns = [
  {
    accessor: 'title',
    Header: 'Developer',
    Cell: rowData => {
      const { avatar, color, title } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative">
          <Avatar
            name={avatar.name}
            src={avatar.img}
            width="60"
            alt=""
            mediaClass={`text-${color} bg-soft-${color} fs-0`}
          />
          <div className="flex-1 ms-3">
            <h6 className="mb-0 fw-semi-bold">
              <Link
                className="text-dark stretched-link"
                to={`/dashboard/developer?dev1=${avatar.name}`}
              >
                {title}
              </Link>
            </h6>
            <p className="fs--2 mb-0 text-500">{avatar.name}</p>
          </div>
        </Flex>
      );
    }
  },

  {
    accessor: 'reviews',
    Header: 'Reviews',
    cellProps: {
      className: 'text-center fw-semi-bold fs--1'
    },
    headerProps: {
      className: 'text-center'
    }
  }
];

const RankingReviewers = ({ dataset }) => {
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={dataset}
      sortable
      pagination
      perPage={5}
    >
      <Card className="h-100">
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-light text-800 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              borderless: true,
              className: 'fs--2 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  );
};

RankingReviewers.propTypes = {
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.shape({
        name: PropTypes.string.isRequired,
        img: PropTypes.string.isRequired
      }).isRequired,
      color: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      reviews: PropTypes.string.isRequired // Correct the prop name from 'reviews' to 'reviews'
    })
  ).isRequired
};

export default RankingReviewers;
