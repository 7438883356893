import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import axiosInstance from 'http/axiosConfig';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const SettingsView = () => {
  const {
    config: { isDark, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [slackNotifications, setSlackNotifications] = useState(false);
  const [mailNotifications, setMailNotifications] = useState(false);

  const [slackWebhook, setSlackWebhook] = useState();
  const [mailAddressList, setMailAddressList] = useState();
  const toggleDarkMode = () => {
    setConfig('isDark', !isDark);
  };
  useEffect(() => {
    const fetchInitialData = async () => {
      console.log('fetch');
      try {
        const response = await axiosInstance.get('/get-notifications-config/');
        console.log('fetch2');
        const smartNotifications = response.data.smart_notifications;
        const mails = smartNotifications.mails;
        const slackWebhook = smartNotifications.slack_webhook;
        setMailAddressList(mails);
        setSlackWebhook(slackWebhook);
        if (mails !== '') {
          setMailNotifications(true);
        }
        if (slackWebhook !== '') {
          setSlackNotifications(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log('fetch3');
        console.log(error);
        // Handle error
      }
    };

    fetchInitialData();
  }, []);
  const saveNotificationsConfig = async () => {
    setIsSaveLoading(true);
    try {
      const response = await axiosInstance.post('/save-notifications-config/', {
        slackWebhook: slackWebhook,
        mailAddressList: mailAddressList
      });
      console.log('Data sent successfully:', response.data);
      // Display toast notification
      toast.success('Data saved successfully!', { position: 'bottom-right' });
    } catch (error) {
      console.error('Error sending data:', error);
      toast.error('Error saving data!', { position: 'bottom-right' });
    }
    setIsSaveLoading(false);
  };

  const toggleMenuCollapse = () => {
    setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
  };

  const toggleSlackNotifications = () => {
    setSlackNotifications(!slackNotifications);
    setSlackWebhook('');
  };

  const handleSlackWebhookChange = event => {
    const newWebhook = event.target.value;
    setSlackWebhook(newWebhook);
  };

  const toggleMailNotifications = () => {
    setMailNotifications(!mailNotifications);
    setMailAddressList('');
  };

  const handleMailWebhookChange = event => {
    const newMailAddress = event.target.value;
    setMailAddressList(newMailAddress);
  };

  const renderSlackWebhookInput = () => {
    if (slackNotifications) {
      return (
        <Row className="align-items-center">
          <Col xs="auto"></Col>
          <Col className="mr-3">
            <Form.Label className="form-switch-label">
              <h6>Slack Webhook</h6>
            </Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              id="slackWebhookInput"
              value={slackWebhook}
              onChange={handleSlackWebhookChange}
              placeholder="Enter your Slack Webhook URL (e.g., https://hooks.slack.com/services/T01234567/B01234567/abcdefgh12345678)"
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderMailWebhookInput = () => {
    if (mailNotifications) {
      return (
        <Row className="align-items-center">
          <Col xs="auto"></Col>
          <Col className="mr-3">
            <Form.Label className="form-switch-label">
              <h6>Mail Address</h6>
            </Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              id="mailAddressInput"
              value={mailAddressList}
              onChange={handleMailWebhookChange}
              placeholder="Enter your Mail Address (e.g., info@gitlighs.com)"
            />
          </Col>
        </Row>
      );
    }
    return null;
  };
  return (
    <Row className="g-3 mb-3">
      <Col md={12}>
        <h2 className="mb-4">Personalize Your UI</h2>

        <div className="settings-section">
          <Row className="align-items-center g-3 mb-3">
            <Col xs="auto"></Col>
            <Col className="mr-3">
              <Form.Label className="form-switch-label">
                <h5>
                  <FontAwesomeIcon
                    icon={isDark ? 'moon' : 'sun'}
                    className="fs-0 theme-control-toggle-label"
                  />
                  Dark Mode{' '}
                </h5>
              </Form.Label>
            </Col>
            <Col>
              <Form.Check
                type="switch"
                id="darkModeSwitch"
                checked={isDark}
                onChange={toggleDarkMode}
              />
            </Col>
          </Row>
        </div>

        <div className="settings-section">
          <Row className="align-items-center g-3 mb-3">
            <Col xs="auto"></Col>
            <Col className="mr-3">
              <Form.Label className="form-switch-label">
                <h5>
                  <FontAwesomeIcon
                    icon={
                      isNavbarVerticalCollapsed ? 'angle-right' : 'angle-left'
                    }
                    className="fs-0 theme-control-toggle-label"
                  />
                  Menu Collapse{' '}
                </h5>
              </Form.Label>
            </Col>
            <Col>
              <Form.Check
                type="switch"
                id="menuCollapseSwitch"
                checked={isNavbarVerticalCollapsed}
                onChange={toggleMenuCollapse}
              />
            </Col>
          </Row>
        </div>

        {isLoading ? (
          <Spinner
            className="position-absolute start-50"
            animation="border"
            size="xl"
          />
        ) : (
          <div className="settings-section">
            <Row className="align-items-center g-3 mb-3">
              <h2 className="mb-4">Smart notifications</h2>
            </Row>
            <Row>
              <Col xs="auto"></Col>
              <Col className="mr-3">
                <Form.Label className="form-switch-label">
                  <h5>
                    <FontAwesomeIcon
                      icon={slackNotifications ? 'toggle-on' : 'toggle-off'}
                      className="fs-0 theme-control-toggle-label"
                    />
                    Mail Notifications{' '}
                  </h5>
                </Form.Label>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="mailNotificationsSwitch"
                  checked={mailNotifications}
                  onChange={toggleMailNotifications}
                />
              </Col>
              {renderMailWebhookInput()}
            </Row>
            <Row>
              <Col xs="auto"></Col>
              <Col className="mr-3">
                <Form.Label className="form-switch-label">
                  <h5>
                    <FontAwesomeIcon
                      icon={slackNotifications ? 'toggle-on' : 'toggle-off'}
                      className="fs-0 theme-control-toggle-label"
                    />
                    Slack Notifications{' '}
                  </h5>
                </Form.Label>
              </Col>
              <Col>
                <Form.Check
                  type="switch"
                  id="slackNotificationsSwitch"
                  checked={slackNotifications}
                  onChange={toggleSlackNotifications}
                />
              </Col>
              {renderSlackWebhookInput()}
            </Row>
          </div>
        )}
      </Col>
      <Row>
        <Col md={12}>
          {isSaveLoading ? (
            <Spinner
              animation="border"
              role="status"
              className="my-accounts-save-users"
            ></Spinner>
          ) : (
            <button
              onClick={saveNotificationsConfig}
              className="btn btn-primary mt-3 my-accounts-save-users"
            >
              Save
            </button>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default SettingsView;
