import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const AdvanceSearchTable = ({ dataset, perPage = 5 }) => {
  return (
    <>
      <AdvanceTableWrapper
        columns={dataset.columns}
        data={dataset.tableData}
        sortable
        pagination
        perPage={perPage}
      >
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox table />
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter
            rowCount={dataset.tableData.length}
            table
            rowInfo
            navButtons
            perPage={perPage}
            rowsPerPageSelection
          />
        </div>
      </AdvanceTableWrapper>
    </>
  );
};

AdvanceSearchTable.propTypes = {
  dataset: PropTypes.shape({
    columns: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired
  }).isRequired,
  perPage: PropTypes.number
};

export default AdvanceSearchTable;
