import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ToolboxComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createRoundedImage } from './imageUtils';
echarts.use([ToolboxComponent]);

const DEFAULT_INTENSITY_VALUE = 10;
const QuadrantChart = ({
  dataset,
  feature,
  type = 'scatter',
  showLegend = true
}) => {
  const [roundedImageUrls, setRoundedImageUrls] = useState([]);

  const getColorByIndex = index => {
    const colors = ['success', 'info', 'warning', 'danger'];
    return colors[index % colors.length];
  };

  const createRoundedImages = async () => {
    const urls = await Promise.all(
      dataset.seriesData.map(async quadrant => {
        if (quadrant.imageUrl) {
          const roundedImageUrl = await createRoundedImage(
            quadrant.imageUrl,
            100,
            50
          );
          return roundedImageUrl;
        }
        return null;
      })
    );
    setRoundedImageUrls(urls);
  };

  useEffect(() => {
    createRoundedImages();
  }, [dataset]);

  const getQuadrant = () => ({
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'none'
      },
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: function (params) {
        const seriesName = params.seriesName;
        const data = params.data;
        const formattedX = data[0] !== 1 ? data[0].toFixed(2) : '';
        const formattedY = data[1] !== 1 ? data[1].toFixed(2) : '';

        const formattedValue = (data[2] || DEFAULT_INTENSITY_VALUE).toFixed(2);

        return `<strong>${seriesName}</strong><br/>
        ${formattedX !== '' ? `X: ${formattedX}<br/>` : ''}
        ${formattedY !== '' ? `Y: ${formattedY}<br/>` : ''}
        ${formattedValue !== '' ? `Intensity: ${formattedValue}` : ''}`;
      }
    },
    toolbox: {
      top: 0,
      feature: feature,
      iconStyle: {
        borderColor: getColor('gray-700'),
        borderWidth: 1
      },

      emphasis: {
        iconStyle: {
          textFill: getColor('gray-600')
        }
      }
    },
    xAxis: {
      name: dataset.xAxisName,
      type: 'value',
      nameLocation: 'end',
      nameTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        align: 'right',
        padding: 10
      },
      axisLabel: {
        color: getColor('gray-600')
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-500')
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: getColor('gray-200')
        }
      }
    },
    yAxis: {
      name: dataset.yAxisName,
      type: 'value',
      nameLocation: 'end',
      nameTextStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        verticalAlign: 'bottom',
        align: 'left',
        padding: 10
      },
      nameGap: -20,
      axisLabel: {
        color: getColor('gray-600')
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-500')
        }
      }
    },
    legend: {
      show: showLegend,
      data: dataset.seriesData.map(quadrant => quadrant.name),
      textStyle: {
        color: getColor('gray-600')
      },
      right: 10,
      bottom: 20,
      backgroundColor: getColor('gray-200')
    },

    series: dataset.seriesData.map((quadrant, index) => ({
      name: quadrant.name,
      data: quadrant.data,
      type: type,
      smooth: true,
      symbolSize: function (data) {
        return (data[2] || DEFAULT_INTENSITY_VALUE) * 8;
      },
      symbol: quadrant.imageUrl
        ? 'image://' + roundedImageUrls[index]
        : 'circle',

      itemStyle: {
        color: getColor(getColorByIndex(index)),
        borderColor: getColor(getColorByIndex(index))
      }
    })),

    grid: {
      right: 8,
      left: 5,
      bottom: 5,
      top: 8,
      containLabel: true
    }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getQuadrant()}
      style={{ height: '30rem' }}
    />
  );
};

QuadrantChart.propTypes = {
  dataset: PropTypes.arrayOf(PropTypes.array),
  xAxisName: PropTypes.string.isRequired,
  yAxisName: PropTypes.string.isRequired,
  type: PropTypes.string,
  feature: PropTypes.string,
  showLegend: PropTypes.bool
};

export default QuadrantChart;
